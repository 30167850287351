import service from '@/utils/request'; // @Tags ResourceBrand
// @Summary 创建EquityBrand
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceBrand true "创建EquityBrand"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityBrands/createEquityBrand [post]

export var createEquityBrand = function createEquityBrand(data) {
  return service({
    url: "/equityBrands/createEquityBrand",
    method: 'post',
    data: data
  });
}; // @Tags ResourceBrand
// @Summary 删除EquityBrand
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceBrand true "删除EquityBrand"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityBrands/deleteEquityBrand [delete]

export var deleteEquityBrand = function deleteEquityBrand(data) {
  return service({
    url: "/equityBrands/deleteEquityBrand",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceBrand
// @Summary 删除EquityBrand
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityBrand"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityBrands/deleteEquityBrand [delete]

export var deleteEquityBrandByIds = function deleteEquityBrandByIds(data) {
  return service({
    url: "/equityBrands/deleteEquityBrandByIds",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceBrand
// @Summary 更新EquityBrand
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceBrand true "更新EquityBrand"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityBrands/updateEquityBrand [put]

export var updateEquityBrand = function updateEquityBrand(data) {
  return service({
    url: "/equityBrands/updateEquityBrand",
    method: 'put',
    data: data
  });
}; // @Tags ResourceBrand
// @Summary 用id查询EquityBrand
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceBrand true "用id查询EquityBrand"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityBrands/findEquityBrand [get]

export var findEquityBrand = function findEquityBrand(params) {
  return service({
    url: "/equityBrands/findEquityBrand",
    method: 'get',
    params: params
  });
}; // @Tags ResourceBrand
// @Summary 分页获取EquityBrand列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityBrand列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityBrands/getEquityBrandList [get]

export var getEquityBrandList = function getEquityBrandList(params) {
  return service({
    url: "/equityBrands/getEquityBrandList",
    method: 'get',
    params: params
  });
};